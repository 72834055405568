<template>
  <div id="app">
    <template v-if="this.$route.meta.layout == 'checkout'">
      <router-view />
      <notifier />
    </template>
    <template v-else-if="isMobileApp">
      <router-view :class="{'p-3': this.$route.meta.layout != 'fullWidth'}" />
    </template>
    <template v-else>
      <notifier />
      <newsletterModal />
      <div class="mst-alert" v-show="showBanner">
        <p>
          <span>FIRST DAY OF SPRING - 15% OFF EVERYTHING, WITH CODE:</span> <strong>SPRING</strong>
        <!-- <p>15% OFF SITE WIDE WITH CODE <strong>XMAS15</strong> ENDS SOON -->
          <!-- <p>All orders placed after 11am 23rd December 2024 will be dispatched from 3rd January 2025.</p> -->
          <!-- <router-link :to="{path:'/post-disruption'}" class="d-block d-md-inline">more information</router-link> -->
        </p>
      </div>
      <!-- <div class="tradeup">
        <p>
          Buying for business? Discover the
          <router-link :to="{path:'/trade-pricing'}">MStore Trade Account</router-link>today.
        </p>
      </div>-->
      <vave-header v-if="this.$route.meta.layout !== 'focus'" />
      <div v-else id="focus-header">
        <div class="container-fluid">
          <div class="row justify-content-center align-items-center">
            <div class="col-auto">
              <img src="https://res.cloudinary.com/mstore/image/upload/v1633444335/MSTORE-LOGO_xcnbjt.svg" width="100px" color="white" alt="MStore" title="MStore" />
            </div>
          </div>
        </div>
      </div>

      <div id="content">
        <transition name="slide">
          <div class="banner-alert" v-if="$store.state.cart.coupon.message !== null">
            {{ $store.state.cart.coupon.message }}
            <span>"{{ $store.state.cart.coupon.code }}"</span>
          </div>
        </transition>

        <portal-target name="before-breadcrumbs"></portal-target>

        <div class="container mt-5" v-if="this.$route.meta.layout == 'standard'">
          <div class="row d-none d-md-flex">
            <div class="col-auto" style="padding-right:0px;">
              <span class="breadcrumb-home">
                <router-link to="/">Home</router-link>
              </span>
            </div>
            <div class="col" style="padding-left:0px;">
              <Breadcrumbs />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <router-view></router-view>
            </div>
          </div>
        </div>

        <div v-if="this.$route.meta.layout == 'fullWidth' || this.$route.meta.layout == 'focus'">
          <router-view></router-view>
        </div>
      </div>

      <vave-footer v-if="this.$route.meta.layout !== 'focus'" />
      <div v-else id="focus-footer"></div>
    </template>
  </div>
</template>

<script>
import notifier from "./components/notifier";
import vaveHeader from "./components/structure/header";
import vaveFooter from "./components/structure/footer";
import newsletterModal from "./components/newsletterModal";
import hitEvent from "./events/eventTypes/hit";
import isOrganic from "./is-organic";

export default {
  name: "App",
  data: () => {
    return {
      isMobileApp: false,
    };
  },
  computed: {
    showBanner() {
      const startDate = new Date("2025-03-20T00:00:01");
      const endDate = new Date("2025-03-23T23:59:59");
      const currentDate = new Date();
      return currentDate >= startDate && currentDate <= endDate;
    },
    authUserEmail() {
      // AC tracking
      return this.$store.state.auth.user.email;
    },
  },
  mounted() {
    //if some other component doesnt set prerenderReady to true, set it to true after 20 seconds
    setTimeout(() => {
      window.prerenderReady = true;
    }, 20000);

    if (this.$route.params.coupon) {
      this.$store.dispatch("cart/setCoupon", {
        coupon: this.$route.params.coupon,
      });
    } else {
      // this.$store.dispatch("cart/setCoupon", 'HMC36XCU');
    }
    this.isMobileApp = navigator.userAgent.match("vave-mobile-app");
    // AC tracking
    if (this.$store.state.auth.user.email) {
      window.vgo("setEmail", this.$store.state.auth.user.email);
      window.vgo("process");
    }

    var utms = {};
    // Get UTM params
    let urlSearchParams = new URLSearchParams(window.location.search);
    [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_id",
      "utm_term",
      "utm_content",
    ].forEach(
      function (param) {
        if (urlSearchParams.has(param)) {
          utms[param] = urlSearchParams.get(param);
        }
      }.bind(this)
    );
    // Replace the UTM params in the store
    if (Object.keys(utms).length > 0) {
      // If we have utm params, set the source type as UTM
      utms["source_type"] = "utm";
    } else {
      // Otherwise set it based on the referrer
      utms["source_type"] = isOrganic(document.referrer) ? "organic" : "direct";
    }
    // After all the other checks, set the referrer
    utms["referer"] = document.referrer;

    this.$store.dispatch("app/setUtmValues", utms);
  },
  watch: {
    authUserEmail() {
      // AC tracking
      window.vgo("setEmail", this.$store.state.auth.user.email);
      window.vgo("process");
    },
    $route: {
      deep: true,
      handler() {
        new hitEvent(window.location.href).dispatch();
        if (this.$route.meta.pageTitle) {
          window.setTitle(this.$route.meta.pageTitle);
        }
      },
    },
  },
  methods: {
    deleteCartID() {
      this.$store.commit("cart/deleteCartID");
    },
  },
  components: {
    notifier,
    vaveHeader,
    vaveFooter,
    newsletterModal,
  },
};
</script>

<style lang="scss">
.form-control,
.form-select {
  outline: none !important;
  box-shadow: none !important;
}
#mainNav {
  background: black;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 51;
  a {
    color: white;
    text-decoration: none;
  }
}
.banner-alert {
  background: #feb103;
  background: radial-gradient(
    circle,
    rgb(255, 203, 89) 0%,
    rgba(254, 177, 3, 1) 100%
  );
  color: rgb(37, 37, 37);
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
  margin-top: -20px;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba($color: #000000, $alpha: 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90px;
  position: relative;
  z-index: 50;
  span {
    display: block;
    font-size: 1.2em;
  }
}

.slide-enter,
.slide-leave-to {
  height: 0px;
  transform: translateY(-90px);
}

.slide-enter-active {
  transition: all 700ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slide-leave-active {
  transition: all 700ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slide-enter-to {
  height: 90px;
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#content {
  flex-grow: 1;
}

#focus-header {
  min-height: 20px;
  background: #202122;
  border-bottom: 5px solid #feb103;
  padding: 20px;
}

#focus-footer {
  height: 20px;
  background: #202122;
  border-top: 5px solid #feb103;
}
.outline {
  &.outline-red {
    border: 1px solid red;
  }
  &.outline-pink {
    border: 1px solid pink;
  }
  &.outline-blue {
    border: 1px solid blue;
  }
  &.outline-green {
    border: 1px solid green;
  }
  &.outline-yellow {
    border: 1px solid yellow;
  }
}

.container {
  // max-width: 1570px!important;
}

#guides {
  // display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999999;
  opacity: 0.5;
  pointer-events: none;
  .container {
    border-left: 1px solid blue;
    border-right: 1px solid blue;
    height: 100%;
    .col {
      height: 100vh;
      border-left: 1px solid yellow;
      &:first-child {
        border-left: none;
      }
    }
  }
}

.mst-alert {
  background: rgb(128, 8, 8);
  background: #feb103;
  color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  position: sticky;
  top: 0px;
  z-index: 9999999;
  p {
    margin: 0;
  }
  a {
    &:hover {
      color: #fff;
    }
  }
}

.tradeup {
  background: #000;
  color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  p {
    margin: 0;
  }
  a {
    &:hover {
      color: #fff;
    }
  }
}
</style>
